import { Intent } from "./model";

const paypalBusinessTrust = "6KSYB7J34SPES";
const paypalItemNameTrust = "IHRC Trust Donation";
const publicBaseUrlTrust = "https://donations.ihrc.org.uk";
const apiBaseUrlTrust = "https://donations.ihrc.org.uk";

const paypalBusinessLimited = "donationslimited@ihrc.org";
const paypalItemNameLimited = "IHRC Donation";
const publicBaseUrlLimited = "https://donations-limited.ihrc.org.uk";
const apiBaseUrlLimited = "https://donations-limited.ihrc.org.uk";

export const isTrustOrLimited = "limited" as "trust" | "limited";

export const isGiftaidSupported = isTrustOrLimited === "trust" ? true : false;

export const restrictedDonationIntents: Intent[] | undefined =
  isTrustOrLimited === "trust" ? undefined : ["General"];

export const isMonthlyDonationsSupported =
  isTrustOrLimited === "trust" ? true : false;

export const apiBaseUrl =
  isTrustOrLimited === "trust" ? apiBaseUrlTrust : apiBaseUrlLimited; // "https://localhost:44375"; //

export const publicBaseUrl =
  isTrustOrLimited === "trust" ? publicBaseUrlTrust : publicBaseUrlLimited;

export const isSagePaySupported = isTrustOrLimited === "trust" ? true : false;

export const paypalBusiness =
  isTrustOrLimited === "trust" ? paypalBusinessTrust : paypalBusinessLimited;
export const paypalItemName =
  isTrustOrLimited === "trust" ? paypalItemNameTrust : paypalItemNameLimited;
