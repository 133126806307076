import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltCircleRight,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { ApiDonationType, postDonation } from "./apiAdapter";
import { Basket } from "./Basket";
import {
  apiBaseUrl,
  isMonthlyDonationsSupported,
  isTrustOrLimited,
  paypalBusiness,
  paypalItemName,
  publicBaseUrl,
} from "./config";
import {
  getApiDonationTypesAction,
  getSetSelectedDonationTypeId,
} from "./context/reducer";
import { Context } from "./context/store";
import { SmallButton } from "./designSystem/Button";
import { mainTextFontFamily, mainTextFontWeight } from "./designSystem/styles";
import { DonationAmountPage } from "./DonationAmountPage";
import { Footer } from "./Footer";
import logo from "./images/logo.png";
import { Jumbotron } from "./Jumbotron";
import { DonationDto } from "./model";
import { DynamicImage } from "./utils/useWindowResize";

import "bootstrap/dist/css/bootstrap.min.css";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { DonationsTarget } from "./DonationsTarget";
import bbCodeParser from "bbcode-to-react";

const topTextColor = "rgb(0, 65, 0)";
const fontFamily = "Roboto, sans-serif";
const topTextFontSize = 19;
const topTextFontWeight = 500;

const topIconColor = "rgb(0, 128, 0)";

const TopBarIcon = ({ icon }: { icon: IconProp }) => (
  <FontAwesomeIcon
    icon={icon}
    style={{ color: topIconColor, fontSize: 28, fontWeight: 900, width: 35 }}
  />
);

const TopBarText: React.FC<{}> = ({ children }) => (
  <span
    style={{
      fontFamily,
      fontSize: topTextFontSize,
      fontWeight: topTextFontWeight,
      color: topTextColor,
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </span>
);

const Wrapper: React.FC<{}> = ({ children }) => (
  <Col className="w-100 flex-grow-1">
    <Row className="align-items-center" style={{ padding: "10px 20px" }}>
      <Col sm className="w-100" style={{}}>
        <a href="https://www.ihrc.org.uk">
          <img src={logo} alt="logo" style={{ height: 60 }} />
        </a>
      </Col>
      <Col sm="auto" style={{ marginTop: 10, marginBottom: 10 }}>
        <Row className="align-items-end flex-sm-nowrap flex-shrink-1">
          <Col sm style={{ marginTop: 5, marginBottom: 5 }}>
            <Row className="justify-content-center align-items-center flex-nowrap row-cols-auto">
              <Col style={{ paddingRight: 0 }}>
                <TopBarIcon icon={faPhone} />
              </Col>
              <Col style={{ paddingLeft: 3 }}>
                <TopBarText>+44 208 904 4222</TopBarText>
              </Col>
            </Row>
          </Col>
          <Col sm style={{ marginTop: 5, marginBottom: 5 }}>
            <Row className="justify-content-center align-items-center flex-nowrap row-cols-auto">
              <Col style={{ paddingRight: 0 }}>
                <TopBarIcon icon={faEnvelope} />
              </Col>
              <Col style={{ paddingLeft: 3 }}>
                <TopBarText>info@ihrc.org</TopBarText>
              </Col>
            </Row>
          </Col>
          {/* <Col sm style={{ textAlign: "center" }} className="flex-shrink-1">
            Social
          </Col> */}
        </Row>
      </Col>
    </Row>
    <Row
      style={{
        backgroundColor: "rgb(0, 124, 0)",
        borderTop: "2px solid rgb(0, 124, 0)",
      }}
      className="justify-content-center align-items-center row-cols-auto"
    >
      {isTrustOrLimited === "trust" && (
        <Col
          style={{
            fontFamily: mainTextFontFamily,
            fontSize: 18,
            fontWeight: 600,
            color: "white",
            textAlign: "center",
            padding: "10px 20px",
          }}
        >
          IHRC Trust operates on a 100% donations policy. Every single penny
          donated to us is used strictly for charitable efforts
        </Col>
      )}
    </Row>
    {children}
  </Col>
);

// Details: {
//   AdditionalContent?: string;
//   Content?: string;
//   Id: number;
//   Size: 3 | 6 | 12; // expand
//   Type: 1 | 2 | 3; // 1 = text, 2 = image, 3 = video
// }[];

export const DonationTypeDetails: React.FC<{
  details: ApiDonationType["Details"];
}> = ({ details }) => (
  <Col md>
    <Row style={{ backgroundColor: "rgb(243, 240, 235)", padding: 20 }}>
      {details.map((it) => {
        if (it.Type === 1) {
          return (
            <Col key={it.Id} md={it.Size}>
              {it.Content.split("\r\n").map((line) => {
                const parsed = bbCodeParser.toReact(line);

                // const noP =
                //   line.startsWith("[list") ||
                //   line.startsWith("[*]") ||
                //   line.startsWith("[/list]");

                // if (noP) return parsed;

                return <p>{parsed}</p>;
              })}
            </Col>
          );
        }
      })}
    </Row>
  </Col>
);

const DonationPage: React.FC<{}> = () => {
  const {
    dispatch,
    state: { apiDonationTypes, selectedDonationTypeId },
  } = useContext(Context);

  if (!selectedDonationTypeId) return null;

  const donationType = apiDonationTypes.donationTypes.find(
    ({ Id }) => Id === selectedDonationTypeId
  );
  if (!donationType) return null;

  return (
    <>
      <Jumbotron
        apiImagePath={donationType.ImagePath}
        imageFocus={donationType.ImageFocus}
        title={donationType.Name}
        text={donationType.Summary}
        donationType={donationType}
      />
      <DonationAmountPage donationType={donationType} />
      <DonationTypeDetails details={donationType.Details} />
    </>
  );
};

export const BasketCol = styled(Col)`
  background-color: rgb(0, 124, 0);
  order: 1;

  @media (min-width: 768px) {
    width: 400px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    order: 2;
  }
`;

const MainAppRow = styled(Row)`
  margin: 0;

  @media (min-width: 768px) {
    flex-direction: column;
    ${(props) => (props.$useRightMargin ? "margin-right: 400px;" : "")}

    order: 1;
  }
`;

const useCompleteDonation = () => {
  const {
    state: { donation },
  } = useContext(Context);

  const donationAmount = useMemo(
    () => donation.donationAmounts.reduce((acc, { amount }) => acc + amount, 0),
    [donation]
  );

  const [state, setState] = useState<"idle" | "completing" | "redirecting">(
    "idle"
  );

  const paypalSinglePaymentFormRef = useRef<HTMLFormElement | undefined>(
    undefined
  );
  const paypalMonthlyPaymentFormRef = useRef<HTMLFormElement | undefined>(
    undefined
  );
  const sagepaySinglePaymentFormRef = useRef<HTMLFormElement | undefined>(
    undefined
  );

  const [donationIds, setDonationIds] = useState<number[] | undefined>(
    undefined
  );

  const donationIdsAsString = useMemo(
    () => (donationIds ? donationIds.join(",") : ""),
    [donationIds]
  );

  useLayoutEffect(() => {
    if (state === "completing") {
      const dto: DonationDto = {
        source: 1,

        isMonthly: !!donation.isMonthly,
        isGiftaid: !!donation.isGiftaid,
        paymentMethod: donation.paymentMethod,

        donor: {
          firstName: donation.donor?.address?.firstName,
          lastName: donation.donor?.address?.lastName,
          emailAddress: donation.donor?.address?.email,
          telephone: donation.donor?.address?.telephone,
          address: donation.donor?.address?.address,
          address2: donation.donor?.address?.address2,
          city: donation.donor?.address?.city,
          postCode: donation.donor?.address?.postCode,
          country: donation.country,
          state: donation.state,
          emailOptIn: !!donation.emailOptIn,
        },

        donationAmounts: donation.donationAmounts,
      };

      (async () => {
        try {
          setDonationIds(await postDonation(dto));
          setState("redirecting");
        } catch (ex) {
          console.log(ex);
        }
      })();
    }

    if (state === "redirecting") {
      if (donation.paymentMethod === "Paypal") {
        if (!donation.isMonthly) {
          paypalSinglePaymentFormRef.current.style.display = "block";
          paypalSinglePaymentFormRef.current.submit();
        } else {
          paypalMonthlyPaymentFormRef.current.style.display = "block";
          paypalMonthlyPaymentFormRef.current.submit();
        }
      }

      if (donation.paymentMethod === "CreditCard") {
        sagepaySinglePaymentFormRef.current.style.display = "block";
        sagepaySinglePaymentFormRef.current.submit();
      }
    }
  }, [state]);

  const completeDonation = () => {
    setState("completing");
  };

  const CompleteDonation = () => {
    return (
      <div
        id="donation-form-payment-redirect"
        className="modal"
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
          color: "#ffffff",
          backgroundColor: "rgba(0,0,0,0.9)",
          display: state !== "idle" ? "block" : "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            width: "100%",
            marginTop: -60,
            textAlign: "center",
            fontSize: 30,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            {state === "completing"
              ? "Completing your donation..."
              : state === "redirecting"
              ? `Redirecting you to ${
                  donation.paymentMethod === "CreditCard" ? "SagePay" : "PayPal"
                } to make payment`
              : ""}
          </div>

          <form
            ref={paypalSinglePaymentFormRef}
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
            style={{ display: "none" }}
          >
            <input type="hidden" name="cmd" value="_donations" />
            <input type="hidden" name="business" value={paypalBusiness} />
            <input type="hidden" name="lc" value="GB" />
            <input type="hidden" name="item_name" value={paypalItemName} />
            <input
              type="hidden"
              name="item_number"
              value={donationIdsAsString}
            />
            <input type="hidden" name="amount" value={donationAmount} />
            <input type="hidden" name="currency_code" value="GBP" />
            <input type="hidden" name="no_note" value="1" />
            <input type="hidden" name="no_shipping" value="1" />
            <input type="hidden" name="rm" value="1" />
            <input
              type="hidden"
              name="return"
              value={`${apiBaseUrl}/PaypalSuccess?donationIds=${donationIdsAsString}`}
            />
            <input
              type="hidden"
              name="cancel_return"
              value={`${apiBaseUrl}/PaypalCancel?donationIds=${donationIdsAsString}`}
            />
            <input
              type="hidden"
              name="notify_url"
              value={`${publicBaseUrl}/PaypalIpn`}
            />
            <input
              type="hidden"
              name="bn"
              value="PP-DonationsBF:btn_donate_LG.gif:NonHosted"
            />
            <input
              type="submit"
              value="If you are not taken automatically, click here to continue to PayPal"
              className="btn btn-light"
            />
          </form>

          <form
            ref={paypalMonthlyPaymentFormRef}
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
            style={{ display: "none" }}
          >
            <input type="hidden" name="cmd" value="_xclick-subscriptions" />
            <input type="hidden" name="business" value={paypalBusiness} />
            <input type="hidden" name="lc" value="GB" />
            <input type="hidden" name="item_name" value={paypalItemName} />
            <input
              type="hidden"
              name="item_number"
              value={donationIdsAsString}
            />
            <input type="hidden" name="no_note" value="1" />
            <input type="hidden" name="no_shipping" value="1" />
            <input type="hidden" name="rm" value="1" />
            <input
              type="hidden"
              name="return"
              value={`${apiBaseUrl}/PaypalSuccess?donationIds=${donationIdsAsString}`}
            />
            <input
              type="hidden"
              name="cancel_return"
              value={`${apiBaseUrl}/PaypalCancel?donationIds=${donationIdsAsString}`}
            />
            <input
              type="hidden"
              name="notify_url"
              value={`${publicBaseUrl}/PaypalIpn`}
            />
            <input type="hidden" name="src" value="1" />
            <input type="hidden" name="a3" value={donationAmount} />
            <input type="hidden" name="p3" value="1" />
            <input type="hidden" name="t3" value="M" />
            <input type="hidden" name="currency_code" value="GBP" />
            <input
              type="hidden"
              name="bn"
              value="PP-SubscriptionsBF:btn_subscribe_LG.gif:NonHosted"
            />
            <input
              type="submit"
              value="If you are not taken automatically, click here to continue to PayPal"
              className="btn btn-light"
            />
          </form>

          <form
            ref={sagepaySinglePaymentFormRef}
            action={`${apiBaseUrl}/SagepayTransaction`}
            method="post"
            target="_top"
            style={{ display: "none" }}
          >
            <input
              type="hidden"
              name="donationIds"
              value={donationIdsAsString}
            />
            <input
              type="submit"
              value="If you are not taken automatically, click here to continue to SagePay"
              className="btn btn-light"
            />
          </form>
        </div>
      </div>
    );
  };

  return {
    completeDonation,
    CompleteDonation,
  };
};

// todo fix - "donation cancelled" message remains even if user proceeds to add a new donation
const getDonationResult = (): boolean | undefined => {
  const path = window.location.pathname.substring(1).toUpperCase();
  if (path !== "DONATIONRESULT") return;

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as any;

  return (params.success as string)?.toLowerCase() === "true";
};

export const App = () => {
  const {
    dispatch,
    state: { apiDonationTypes, selectedDonationTypeId, donation },
  } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [donation.donationAmounts, selectedDonationTypeId]);

  useEffect(() => {
    dispatch(getApiDonationTypesAction());
  }, []);

  useEffect(() => {
    if (!apiDonationTypes) return;
    if (donation.donationAmounts.length > 0) return; // dev only

    const path = window.location.pathname.substring(1).toUpperCase();
    if (path === "/") return;

    const pathDonationTypeId: number | undefined =
      apiDonationTypes.donationTypeUrlPathStrings.find(({ urlPathStrings }) =>
        urlPathStrings.some(
          (urlPathString) => urlPathString.toUpperCase() === path
        )
      )?.donationTypeId;
    if (!pathDonationTypeId) return;

    if (
      !apiDonationTypes.donationTypes.some(
        ({ Id }) => Id === pathDonationTypeId
      )
    )
      return;

    dispatch(getSetSelectedDonationTypeId(pathDonationTypeId));
  }, [dispatch, apiDonationTypes]);

  const { completeDonation, CompleteDonation } = useCompleteDonation();

  if (!apiDonationTypes)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ height: 300 }} />
        <p className="lead" style={{ padding: 20 }}>
          We're having issues displaying the{" "}
          {isTrustOrLimited === "trust" ? "IHRC Trust" : "IHRC"} donations
          facility for you. Please refresh your browser or try again later.
        </p>
      </div>
    );

  const donationResult = getDonationResult();

  const hasDonationAmounts = donation.donationAmounts.length > 0;

  if (
    (window as any).isAppClosedForMaintenance &&
    window.location.hash !== "#override-app-closed-for-maintenance"
  )
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ height: 300 }} />
        <p className="lead" style={{ padding: 20 }}>
          The IHRC Trust donations facility is currently closed for maintenance.
          Please try again later.
        </p>
      </div>
    );

  return (
    <>
      <MainAppRow $useRightMargin={hasDonationAmounts}>
        <Wrapper>
          <DonationPage />
          {!selectedDonationTypeId && (
            <Container fluid style={{ marginTop: 20, marginBottom: 20 }}>
              {donationResult !== undefined && (
                <Row>
                  <Col>
                    {donationResult ? (
                      <>
                        <h2>Thank you for your donation!</h2>
                        <p className="lead">
                          Your donation has been received. If you would like to
                          make a further donation, please view our current
                          campaigns below:
                        </p>
                      </>
                    ) : (
                      <>
                        <h2>Your donation has been cancelled</h2>
                        <p className="lead">
                          You can try again by selecting from our current
                          campaigns below. If you are having difficulty using
                          our donations facility please contact us for
                          assistance and feedback.
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
              )}
              {hasDonationAmounts && (
                <Row>
                  <Col>
                    <h2>Add Another Donation?</h2>
                    <p className="lead">
                      You can add another{" "}
                      {donation.isMonthly ? "monthly" : "one-off"} donation to
                      any of our current campaigns below or finish and{" "}
                      <SmallButton onClick={completeDonation}>
                        Pay using{" "}
                        {donation.paymentMethod === "CreditCard"
                          ? "Credit/Debit Card"
                          : donation.paymentMethod}{" "}
                        <FontAwesomeIcon
                          icon={faArrowAltCircleRight}
                          style={{}}
                        />
                      </SmallButton>
                    </p>
                    {donation.isMonthly ? (
                      <p>
                        If you would like to add another one-off donation,
                        please pay for this monthly donation first, then come
                        back and make a separate one-off donation.
                      </p>
                    ) : isMonthlyDonationsSupported ? (
                      <p>
                        If you would like to add another monthly donation,
                        please pay for this one-off donation first, then come
                        back and make a separate monthly donation.
                      </p>
                    ) : null}
                  </Col>
                </Row>
              )}
              <Row>
                {apiDonationTypes.donationTypes
                  .filter((donationType) => donationType.IsActive)
                  .sort((a, b) => (a.DisplayOrder ?? 0) - (b.DisplayOrder ?? 0))
                  .map((donationType) => (
                    <Col key={donationType.Id} lg="4" style={{ padding: 20 }}>
                      <DynamicImage
                        url={donationType.ImagePath}
                        imageFocus={donationType.ImageFocus}
                        style={{
                          height: 250,

                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          alignItems: "end",
                        }}
                      >
                        {donationType.DonationsTarget !== 0 && (
                          <DonationsTarget
                            size="small"
                            target={donationType.DonationsTarget}
                            currentTotal={donationType.TotalDonationsAmount}
                          />
                        )}
                        <SmallButton
                          noBorderRadius
                          style={{ width: "100%" }}
                          onClick={() =>
                            dispatch(
                              getSetSelectedDonationTypeId(donationType.Id)
                            )
                          }
                        >
                          {hasDonationAmounts ? "Add Donation" : "Donate"}
                        </SmallButton>
                      </DynamicImage>
                      <h2
                        style={{
                          marginTop: 10,
                          fontFamily: mainTextFontFamily,
                          fontSize: 25,
                          fontWeight: mainTextFontWeight,
                          color: "rgb(0, 124, 0)",
                          textAlign: "center",
                        }}
                      >
                        {donationType.Name}
                      </h2>
                      <p
                        className="lead"
                        style={{
                          fontFamily: mainTextFontFamily,
                          color: "rgb(84, 89, 95)",
                          fontSize: 18,
                          fontWeight: 400,
                          lineHeight: "1.2em",
                          textAlign: "center",
                        }}
                      >
                        {donationType.Summary}
                      </p>
                    </Col>
                  ))}
              </Row>
            </Container>
          )}
        </Wrapper>

        {isTrustOrLimited === "trust" && (
          <Col md style={{ order: 2 }}>
            <Footer />
          </Col>
        )}

        <Basket completeDonation={completeDonation} />
      </MainAppRow>
      <CompleteDonation />
    </>
  );
};
