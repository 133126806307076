import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { getSetSelectedDonationTypeId } from "./context/reducer";
import { Context } from "./context/store";
import { Button } from "./designSystem/Button";
import {
  mainTextColor,
  mainTextFontFamily,
  mainTextFontSize,
  mainTextFontWeight,
  mainTextShadow,
} from "./designSystem/styles";
import { DynamicImage } from "./utils/useWindowResize";
import { ApiDonationType } from "./apiAdapter";
import { DonationsTarget } from "./DonationsTarget";

export const Jumbotron: React.FC<{
  apiImagePath: string;
  imageFocus?: string;
  title: string;
  text: string;
  donationType: ApiDonationType;
}> = ({ apiImagePath, imageFocus, title, text, donationType }) => {
  const { dispatch } = useContext(Context);

  return (
    <Row style={{ minHeight: 550 }}>
      <DynamicImage
        url={apiImagePath}
        imageFocus={imageFocus}
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage:
              "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0.6), rgba(0,0,0,0.9), rgba(0,0,0,0.9)), linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.3), rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0))",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            left: 0,
            color: "white",
          }}
        >
          <Button
            style={{ borderRadius: 0 }}
            onClick={() => dispatch(getSetSelectedDonationTypeId(undefined))}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Donate to our other
            campaigns
          </Button>
        </div>
        <div
          style={{
            paddingTop: 150,
            paddingLeft: 20,
            paddingRight: 20,
            zIndex: 2,
          }}
        >
          <h2
            style={{
              fontFamily: mainTextFontFamily,
              fontSize: mainTextFontSize,
              fontWeight: mainTextFontWeight,
              color: mainTextColor,
              textShadow: mainTextShadow,
              marginBottom: 20,
            }}
          >
            {title}
          </h2>
          <Row>
            <Col lg="6">
              <p
                className="lead"
                style={{
                  fontFamily: mainTextFontFamily,
                  textShadow: mainTextShadow,
                  color: mainTextColor,
                  fontSize: 20,
                  fontWeight: 400,
                  lineHeight: "1.2em",
                }}
              >
                {text}
              </p>
            </Col>
          </Row>
        </div>
        {donationType.DonationsTarget !== 0 && (
          <div
            style={{
              // marginRight: -12,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              border: "0px solid transparent",
              borderRadius: "25px",
              zIndex: 2,
            }}
          >
            <DonationsTarget
              size="large"
              target={donationType.DonationsTarget}
              currentTotal={donationType.TotalDonationsAmount}
            />
          </div>
        )}
      </DynamicImage>
    </Row>
  );
};
