import React, { useEffect } from "react";
import { render } from "react-dom";

import { App } from "./App";
import { Store } from "./context/store";
import { isTrustOrLimited } from "./config";

const Application: React.FC<{}> = () => {
  useEffect(() => {
    document.title = `Donate to ${
      isTrustOrLimited === "trust" ? "IHRC Trust" : "IHRC"
    }`;
  }, []);

  return (
    <Store>
      <App />
    </Store>
  );
};

render(<Application />, document.getElementById("root"));
