import React, { useContext, useLayoutEffect, useRef } from "react";
import { Card, Form } from "react-bootstrap";
import { Context } from "./context/store";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

export const DonationsTarget: React.FC<{
  target: number;
  currentTotal: number;
  size: "small" | "large";
}> = ({ size, target, currentTotal }) => {
  if (target === 0) return null;

  const progress = (currentTotal / target) * 100;

  const styles = buildStyles({
    backgroundColor: "#1ca05a",
    textColor: "#fff",
    pathColor: "#eee",
    trailColor: "transparent",
  });

  //return null;

  return (
    <div
      style={{
        width: size === "small" ? 100 : 200,
        flexShrink: 1,
        backgroundColor: "rgba(255,255,255,1)",
        padding: size === "small" ? 10 : 20,
        borderRadius: size === "small" ? "100px 100px 0 0" : "200px 200px 0 0",
        marginRight: size === "small" ? 10 : 0,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <CircularProgressbar
          value={progress}
          text={`${progress.toFixed(0)}%`}
          background
          backgroundPadding={6}
          styles={{
            ...styles,
            text: {
              ...styles.text,
              dominantBaseline: "middle",
              textAnchor: "middle",
            },
          }}
        />
      </div>
      <div
        style={{
          fontSize: size == "large" ? "larger" : "initial",
          paddingTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <span
          style={{
            color: "#ce0000",
            fontWeight: "bold",
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "auto",
          }}
        >
          £{currentTotal}
        </span>

        <span
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "auto",
          }}
        >
          raised of
        </span>

        <span
          style={{
            color: "#ce0000",
            fontWeight: "bold",
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "auto",
          }}
        >
          £{target}
        </span>

        <span
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "auto",
          }}
        >
          target
        </span>
      </div>
    </div>
  );
};
