import {
  faArrowAltCircleRight,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { BasketCol } from "./App";
import { getSetDonationAction } from "./context/reducer";
import { Context } from "./context/store";
import { BlockButton } from "./designSystem/Button";
import {
  mainTextColor,
  mainTextFontFamily,
  mainTextFontWeight,
} from "./designSystem/styles";
import { isGiftaidSupported } from "./config";

export const Basket: React.FC<{ completeDonation: () => void }> = ({
  completeDonation,
}) => {
  const {
    dispatch,
    state: { apiDonationTypes, donation },
  } = useContext(Context);

  if (donation.donationAmounts.length === 0) return null;

  return (
    <BasketCol md>
      <Row className="h-100">
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
          }}
        >
          <h3
            style={{
              fontFamily: mainTextFontFamily,
              fontSize: 36,
              fontWeight: mainTextFontWeight,
              color: mainTextColor,
              marginBottom: 20,
            }}
          >
            Your Donations
          </h3>
          <div style={{ width: "100%" }}>
            {donation.donationAmounts.map(
              ({ id, donationTypeId, donationTypeGroup, intent, amount }) => {
                const donationTypeName = apiDonationTypes.donationTypes.find(
                  ({ Id }) => Id === donationTypeId
                )!.Name;

                return (
                  <div
                    key={id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      border: "1px solid #ffffff",
                      color: "white",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        flexGrow: 1,
                        padding: 10,
                      }}
                    >
                      <h2>{donationTypeName}</h2>
                      <p className="lead" style={{ margin: 0 }}>
                        {intent}
                      </p>
                      {donationTypeGroup && (
                        <p className="lead" style={{ margin: 0 }}>
                          {donationTypeGroup}
                        </p>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10,
                      }}
                    >
                      <p className="lead" style={{ margin: 0 }}>
                        £{amount.toFixed(2)}
                      </p>
                      {donation.isMonthly && (
                        <p className="lead" style={{ margin: 0 }}>
                          per month
                        </p>
                      )}
                    </div>

                    <div style={{ padding: 10 }}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        onClick={() =>
                          dispatch(
                            getSetDonationAction({
                              ...donation,
                              donationAmounts: donation.donationAmounts.filter(
                                (otherDonationAmount) =>
                                  otherDonationAmount.id !== id
                              ),
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div style={{ height: 40 }}></div>
          <h3
            style={{
              fontFamily: mainTextFontFamily,
              fontSize: 36,
              fontWeight: mainTextFontWeight,
              color: mainTextColor,
              marginBottom: 20,
            }}
          >
            Your Details
          </h3>
          <div style={{ color: "white" }}>
            <p>
              {[
                donation.donor.address.firstName,
                donation.donor.address.lastName,
              ].join(" ")}
              <br />
              {[donation.donor.address.address, donation.donor.address.address2]
                .filter(Boolean)
                .join(", ")}
              <br />

              {[
                [donation.donor.address.city, donation.donor.address.postCode]
                  .filter(Boolean)
                  .join(" "),
                donation.state,
                donation.country,
              ]
                .filter(Boolean)
                .join(", ")}

              <br />
              {donation.donor.address.email}
              <br />
              {donation.donor.address.telephone}
            </p>
            {isGiftaidSupported && (
              <p className="lead">
                <FontAwesomeIcon
                  icon={donation.isGiftaid ? faCheckCircle : faTimesCircle}
                  style={{}}
                />{" "}
                Giftaid
              </p>
            )}
            <p className="lead">
              <FontAwesomeIcon
                icon={donation.emailOptIn ? faCheckCircle : faTimesCircle}
                style={{}}
              />{" "}
              Email Updates
            </p>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ height: 40 }}></div>
          <div>
            <p className="lead" style={{ color: "white", textAlign: "center" }}>
              Your total donation is £
              {donation.donationAmounts
                .reduce((acc, { amount }) => acc + amount, 0)
                .toFixed(2)}
              {donation.isMonthly && " per month"}
            </p>
            <BlockButton active onClick={completeDonation}>
              Pay using{" "}
              {donation.paymentMethod === "CreditCard"
                ? "Credit/Debit Card"
                : donation.paymentMethod}{" "}
              <FontAwesomeIcon icon={faArrowAltCircleRight} style={{}} />
            </BlockButton>
          </div>
        </Col>
      </Row>
    </BasketCol>
  );
};
